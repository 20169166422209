var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: { title: _vm.appName + " - Duplicate Event" }
            }),
            _c("gov-back-link", { attrs: { to: { name: "events-index" } } }, [
              _vm._v("Back to events")
            ]),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "full" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Events")
                        ]),
                        _vm.updateRequestCreated
                          ? [
                              _c(
                                "gov-heading",
                                { attrs: { size: "m", tag: "h3" } },
                                [_vm._v("Duplicate event request")]
                              ),
                              _c("gov-body", [
                                _vm._v(_vm._s(_vm.updateRequestMessage))
                              ]),
                              _c(
                                "gov-back-link",
                                { attrs: { to: { name: "events-index" } } },
                                [_vm._v("Back to events")]
                              )
                            ]
                          : [
                              _c("gov-heading", { attrs: { size: "m" } }, [
                                _vm._v("Duplicate event")
                              ]),
                              _c("gov-body", [
                                _vm._v(
                                  "The events will appear on their own page will be discoverable\n              and filterable by visitors based on the information you\n              provide"
                                )
                              ]),
                              _vm.form.$errors.any()
                                ? _c(
                                    "gov-error-summary",
                                    { attrs: { title: "Check for errors" } },
                                    [
                                      _c(
                                        "gov-list",
                                        _vm._l(_vm.form.$errors.all(), function(
                                          error,
                                          field
                                        ) {
                                          return _c("li", {
                                            key: field,
                                            domProps: {
                                              textContent: _vm._s(error[0])
                                            }
                                          })
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "gov-tabs",
                                {
                                  attrs: {
                                    tabs: _vm.allowedTabs,
                                    "no-router": ""
                                  },
                                  on: { "tab-changed": _vm.onTabChange }
                                },
                                [
                                  _c("details-tab", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isTabActive("details"),
                                        expression: "isTabActive('details')"
                                      }
                                    ],
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      id: _vm.event.id,
                                      title: _vm.form.title,
                                      slug: _vm.form.slug,
                                      start_date: _vm.form.start_date,
                                      end_date: _vm.form.end_date,
                                      start_time: _vm.form.start_time,
                                      end_time: _vm.form.end_time,
                                      intro: _vm.form.intro,
                                      description: _vm.form.description,
                                      is_free: _vm.form.is_free,
                                      fees_text: _vm.form.fees_text,
                                      fees_url: _vm.form.fees_url,
                                      organiser_name: _vm.form.organiser_name,
                                      organiser_phone: _vm.form.organiser_phone,
                                      organiser_email: _vm.form.organiser_email,
                                      organiser_url: _vm.form.organiser_url,
                                      booking_title: _vm.form.booking_title,
                                      booking_summary: _vm.form.booking_summary,
                                      booking_url: _vm.form.booking_url,
                                      booking_cta: _vm.form.booking_cta,
                                      homepage: _vm.form.homepage,
                                      organisations: _vm.organisations,
                                      organisation_id: _vm.form.organisation_id,
                                      is_virtual: _vm.form.is_virtual,
                                      location_id: _vm.form.location_id,
                                      image_file_id: _vm.form.image_file_id
                                    },
                                    on: {
                                      "update:title": [
                                        _vm.onUpdateTitle,
                                        function($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "title",
                                            $event
                                          )
                                        }
                                      ],
                                      "update:slug": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "slug",
                                          $event
                                        )
                                      },
                                      "update:start_date": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:end_date": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:start_time": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "start_time",
                                          $event
                                        )
                                      },
                                      "update:end_time": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "end_time",
                                          $event
                                        )
                                      },
                                      "update:intro": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "intro",
                                          $event
                                        )
                                      },
                                      "update:description": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "description",
                                          $event
                                        )
                                      },
                                      "update:is_free": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "is_free",
                                          $event
                                        )
                                      },
                                      "update:fees_text": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "fees_text",
                                          $event
                                        )
                                      },
                                      "update:fees_url": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "fees_url",
                                          $event
                                        )
                                      },
                                      "update:organiser_name": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "organiser_name",
                                          $event
                                        )
                                      },
                                      "update:organiser_phone": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "organiser_phone",
                                          $event
                                        )
                                      },
                                      "update:organiser_email": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "organiser_email",
                                          $event
                                        )
                                      },
                                      "update:organiser_url": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "organiser_url",
                                          $event
                                        )
                                      },
                                      "update:booking_title": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "booking_title",
                                          $event
                                        )
                                      },
                                      "update:booking_summary": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "booking_summary",
                                          $event
                                        )
                                      },
                                      "update:booking_url": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "booking_url",
                                          $event
                                        )
                                      },
                                      "update:booking_cta": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "booking_cta",
                                          $event
                                        )
                                      },
                                      "update:homepage": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "homepage",
                                          $event
                                        )
                                      },
                                      "update:organisation_id": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "organisation_id",
                                          $event
                                        )
                                      },
                                      "update:is_virtual": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "is_virtual",
                                          $event
                                        )
                                      },
                                      "update:location_id": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "location_id",
                                          $event
                                        )
                                      },
                                      "update:image_file_id": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "image_file_id",
                                          $event
                                        )
                                      },
                                      clear: function($event) {
                                        return _vm.form.$errors.clear($event)
                                      },
                                      "image-changed": function($event) {
                                        _vm.imageChanged = $event
                                      },
                                      "alt-text-changed": function($event) {
                                        _vm.altTextChanged = true
                                      }
                                    }
                                  }),
                                  _vm.isTabActive("taxonomies")
                                    ? _c("taxonomies-tab", {
                                        attrs: {
                                          errors: _vm.form.$errors,
                                          "is-global-admin":
                                            _vm.auth.isGlobalAdmin,
                                          type: _vm.form.type,
                                          category_taxonomies:
                                            _vm.form.category_taxonomies
                                        },
                                        on: {
                                          clear: function($event) {
                                            _vm.form.$errors.clear($event)
                                            _vm.errors = {}
                                          },
                                          "update:category_taxonomies": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form,
                                              "category_taxonomies",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("gov-warning-text", [
                                _vm._v(
                                  "\n              Please be aware, by submitting these changes, any pending\n              updates may be overwritten.\n            "
                                )
                              ]),
                              _vm.form.$submitting
                                ? _c(
                                    "gov-button",
                                    { attrs: { disabled: "", type: "submit" } },
                                    [_vm._v("Requesting...")]
                                  )
                                : _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        disabled: _vm.loading || !_vm.event,
                                        type: "submit"
                                      },
                                      on: { click: _vm.onSubmit }
                                    },
                                    [_vm._v(_vm._s(_vm.updateButtonText))]
                                  ),
                              _vm.form.$errors.any()
                                ? _c("ck-submit-error")
                                : _vm._e()
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }